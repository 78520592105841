import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { FooterRecCard } from "../components/resources"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { v4 as uuidv4 } from "uuid"
import Dar from "@mui/icons-material/DoubleArrow"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/security-maturity/",
    label: "SECURITY MATURITY",
  },
]

const resourcesList = [
  {
    title: "VigilantMEDR",
    subtitle: "Managed Endpoint Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MEDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "VigilantMNDR",
    subtitle: " Managed Network Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MNDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "Vigilant365™ ",
    subtitle: "Enhanced Microsoft 365 Detection",
    LinkUr: "https://sp1.sdcdn.app/pdf/V365-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
]

const EvolvingThreats = () => (
  <Layout>
    <Seo title="SECURITY MATURITY" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>

    <div className="grid--bg--for-it-ops-sm">
      <section className="section-title-top--platform ">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h1>SECURITY MATURITY</h1>
              <h4>CONTINUOUSLY LEVELING UP</h4>
              <p className="content">
                Companies today are challenged with finding the expertise, time,
                resources, focus and tradecraft that will guarantee the maximum
                security maturity necessary to protect their products and
                people.
              </p>
              <Link to="/contact/" className="nav-section--cta-btn">
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    increase security today
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
            <div className="section-col-2"></div>
          </div>
        </div>
      </section>
    </div>

    <section className="section-overview">
      <div className="container">
        <br />
        <div className="row pb-5">
          <div className="col-sm-12">
            <div className="section-col-rr">
              <h4>Win the Practice Field, Win on Gameday </h4>
              <hr />
            </div>

            <div className="row">
              <div className="col-6">
                <p>
                  When it comes to cyber security, we exist in an ever-evolving
                  threat landscape. The wise decision-makers in your company
                  recognize the need for state-of-the-art cyber protection, but
                  are unsure about how to find the expertise, time, resources,
                  tradecraft and focus that will mature the entire organization
                  into a modern cyber fortress.
                </p>
                <p>
                  Most people find Security Maturity boring. It is about
                  consistency, responsibility, and intelligence. Your cyber
                  defense should never resemble a young teenager, running from
                  crisis to crisis. Maturity cannot be static, it must be
                  comprehensive and evolving. Our cybersecurity collaboration is
                  no white-gloved butler. Instead, we provide a whole team of
                  hunters and watchmen. Vigilant is your co-vested partner:
                  providing advanced threat assessment, evolving vulnerability
                  detection and Team Centered Support
                </p>
              </div>
              <div className="col-6">
                <p>
                  Security Maturity is about what happens on gameday, it’s also
                  about what happens on the practice field. If you win the
                  practice field, you will also secure victory when the game is
                  on the line. Vigilant’s never-ceasing maturing partnership
                  takes all of your systems through the practice field drills.
                  We help you identify exactly what kind of shape your team and
                  game-plan is in.
                </p>
                <p>
                  The five theaters of cyber war are detection, threat
                  intelligence, response, prevention, and compliance. Vigilant
                  will guide you through all five and provide a plan to secure
                  the high ground. Your organization will receive a
                  state-of-the-art picture of how prepared you are to meet the
                  highest priority threats based on: your exposure, your
                  industry and global trends. Vigilant will also put policy and
                  procedures in place across your organization to provide
                  business continuity and disaster recovery. We Never Quit. We
                  Fight to Defend.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section-box-cta">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <div className="section-col-1--content">
              <h2>
                Sign Up Today for the Industry-Leading <br /> Vigilant Threat
                Audit
              </h2>
              <hr />
              <Link
                to="/security-threat-audit/"
                className="nav-section--cta-btn"
              >
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    sign up today
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section-platform ">
      <div className="container">
        <div className="row">
          <div className="col-sm-7">
            <div className="side-binder-r">
              <h6>CASE STUDY</h6>
              <h3>A VIGILANT SUPER POWER</h3>
            </div>

            <p className="content">
              With Vigilant, you are not paying for a security tool, you are
              paying for our integrated solutions-driven SERVICE. Because of
              this you will not be contractually attached to an outdated tool
              tech. Vigilant has the ability and purpose to keep only the right
              tools in your security belt. Threats are ever-evolving and you
              need a service partner that is able to evolve just as quickly. We
              are tool agnostic so we can be client specific.
            </p>
            <div className="ctizzle">
              <Link
                to="/security-maturity/case-study"
                className="nav-section--cta-btn"
              >
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    read more
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
          </div>
          <div className="col-sm-5">
            <StaticImage
              src="../images/security-maturity/hex.png"
              quality={95}
              formats={["AUTO", "WEBP"]}
              alt="Service Placeholder"
            />
          </div>
        </div>
      </div>
    </section>
    <section className="section-resources">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <h3>RESOURCES</h3>
          </div>
          <div className="section-col-2">
            <ul className="resources-list">
              {resourcesList.map(data => {
                return (
                  <FooterRecCard
                    altTxt={data.title}
                    title={data.title}
                    subTitle={data.subtitle}
                    recCat={data.cat}
                    LinkUrl={data.LinkUr}
                    key={uuidv4()}
                  />
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default EvolvingThreats
